import SearchOverlay from '@/js/components/search/search_overlay';

function requireAll(r) {
  r.keys().forEach(r);
}

const searchOverlay = document.getElementById('searchOverlay');

function initSearchOverlay() {
  // eslint-disable-next-line no-new
  new SearchOverlay(searchOverlay);
}

if (searchOverlay) {
  initSearchOverlay();
}

Array.prototype.slice.call(document.querySelectorAll('[data-print]'))
  .forEach((btn) => {
    // eslint-disable-next-line no-use-before-define
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      window.print();
    });
  });

// load all js files, but exclude those in partials
requireAll(require.context('./', true, /^(?!.*\/partials\/).*.js$/));

import FilterForm from '@/js/components/forms/filter_form';

class Overview {
  constructor() {
    this.dom = {
      filterForm: document.getElementById('filter-form'),
    };

    this.components = {
      filterForm: undefined,
    };

    this.mount();
  }

  mount() {
    this.initializeFilterForm();
  }

  initializeFilterForm() {
    if (this.dom.filterForm) {
      this.dom.filterForm = document.getElementById(this.dom.filterForm.id);
      this.components.filterForm = new FilterForm(this.dom.filterForm);

      this.dom.filterForm.addEventListener('update', () => {
        this.initializeFilterForm();
      });
    }
  }
}

if (document.getElementById('page-testimonials-overview')) {
  // eslint-disable-next-line no-new
  new Overview();
}

import FormFieldDuplicator from '@/js/components/form_field_duplicator/form_field_duplicator';
import ActionHandler from '@/js/components/forms/action_handler';
import ActionComponent from '@/js/components/forms/action_component';
import Validation from '@/js/components/forms/validation';
import Datepicker from '@/js/components/datepicker/datepicker';
import Inputmask from 'inputmask';
import { rootWithLanguage } from '@/js/utils/paths';

class CourseRegistration extends ActionHandler {
  constructor() {
    super();
    this.dom = {
      requestElements: [...document.querySelectorAll('[data-request-url]')],
      datepickers: [...document.querySelectorAll('[data-datepicker]')],
      formParticipants: document.getElementById('form-participants'),
      formInvoice: document.getElementById('form-invoice'),
      formConfirm: document.getElementById('form-confirm'),
      inputMasks: [...document.querySelectorAll('[data-input-mask]')],
      selects: [...document.querySelectorAll('select[data-bs-target]')],
      updatePriceSelectors: [...document.querySelectorAll('input[name="Programs[]"]')],
      modularRadioButton: document.getElementById('modular-yes'),
    };

    this.props = {
      cloneFields: null,
    };

    this.components = {
      participantsFormValidation: null,
      invoiceFormValidation: null,
      confirmFormValidation: null,
    };

    this.events = {
      initFormFieldsDuplicator: this.initFormFieldsDuplicator.bind(this),
      vatChangeEvent: this.vatChangeEvent.bind(this),
      updatePriceSelectorEvent: this.updatePriceSelectorEvent.bind(this),
      modularRadioChangeEvent: this.modularRadioChangeEvent.bind(this),
    };

    this.mount();
  }

  // eslint-disable-next-line class-methods-use-this
  mount() {
    this.events.initFormFieldsDuplicator();

    this.dom.requestElements
      .map((element) => new ActionComponent({ element, url: element.dataset.requestUrl, action: element.dataset.action }));

    this.dom.datepickers
      .map((element) => new Datepicker({
        element, lang: element.dataset.datepicker, minDate: element.dataset.datepickerMinDate, maxDate: element.dataset.datepickerMaxDate,
      }));

    Inputmask().mask(document.querySelectorAll('[data-inputmask]'));

    if (this.dom.formParticipants) {
      this.components.participantsFormValidation = new Validation(this.dom.formParticipants);
    }

    if (this.dom.formInvoice) {
      this.components.invoiceFormValidation = new Validation(this.dom.formInvoice);
    }

    if (this.dom.formConfirm) {
      this.components.confirmFormValidation = new Validation(this.dom.formConfirm);
    }

    // Toggle the VAT fields
    if (this.dom.selects) {
      Array.prototype.slice.call(this.dom.selects).forEach((select) => {
        select.addEventListener('change', this.events.vatChangeEvent);
      });
    }

    // Action to update the price in the sidebar
    if (this.dom.updatePriceSelectors) {
      Array.prototype.slice.call(this.dom.updatePriceSelectors).forEach((element) => {
        element.addEventListener('change', this.events.updatePriceSelectorEvent);
      });
    }

    // Action to reset the section checkboxes + update price in sidebar
    if (this.dom.modularRadioButton) {
      this.dom.modularRadioButton.addEventListener('change', this.events.modularRadioChangeEvent);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  initFormFieldsDuplicator() {
    Array.prototype.slice.call(document.querySelectorAll('[data-copy-element]')).forEach((node) => {
      // eslint-disable-next-line no-new
      new FormFieldDuplicator(node, node.dataset.copyCountMax, node.dataset.copyElement, node.dataset.copyContainer);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  vatChangeEvent(e) {
    document.getElementById(e.currentTarget.dataset.bsTarget).classList.toggle('show');
  }

  // eslint-disable-next-line class-methods-use-this
  updatePriceSelectorEvent() {
    const formData = new FormData();
    const url = `${rootWithLanguage}update-price`;
    const selectedCourseSections = [];

    Array.prototype.slice.call(this.dom.updatePriceSelectors).forEach((checkbox) => {
      if (checkbox.checked === true) {
        selectedCourseSections.push(checkbox.value);
      }
    });

    formData.append('PhaseId', document.getElementById('form-participants').dataset.phaseId);
    formData.append('ProgramIds', selectedCourseSections);

    this.send({
      url,
      formData,
    });
  }

  modularRadioChangeEvent() {
    // Uncheck all modular checkboxes
    Array.prototype.slice.call(this.dom.updatePriceSelectors).forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.checked = false;
    });
    this.updatePriceSelectorEvent();
  }
}

if (document.getElementById('page-course-registration')) {
  // eslint-disable-next-line no-new
  new CourseRegistration();
}

import {
  Collapse,
} from 'bootstrap';
import Datepicker from '@/js/components/datepicker/datepicker';

export default class FormFieldDuplicator {
  constructor(cloneButton, counterMax, copyElem, container) {
    this.dom = {
      cloneButton,
      copyElem,
      container: document.getElementById(container),
    };

    this.props = {
      counter: null,
      counterMax: +counterMax,
      elements: null,
    };

    this.events = {
      onCopyButtonClicked: this.onCopyButtonClicked.bind(this),
      onRemoveButtonClicked: this.onRemoveButtonClicked.bind(this),
      onCreateClone: this.onCreateClone.bind(this),
    };

    this.mount();
  }

  mount() {
    this.props.elements = this.dom.container.getElementsByClassName(this.dom.copyElem);
    this.props.counter = this.props.elements.length;
    this.dom.copyElem = Array.prototype.slice.call(this.props.elements).pop();

    if (!this.dom.copyElem) {
      return;
    }

    if (this.props.counter >= this.props.counterMax) {
      this.dom.cloneButton.classList.add('d-none');
    }

    if (!this.props.counter < this.props.counterMax) {
      this.dom.cloneButton.addEventListener('click', this.events.onCopyButtonClicked);
    }

    Array.prototype.slice.call(this.dom.container.querySelectorAll('.btn-remove'))
      .forEach((btn) => {
        btn.addEventListener('click', this.events.onRemoveButtonClicked);
      });
  }

  // eslint-disable-next-line class-methods-use-this
  onCopyButtonClicked(e) {
    e.preventDefault();

    this.events.onCreateClone();

    this.props.counter += 1;

    if (this.props.counter >= this.props.counterMax) {
      this.dom.cloneButton.classList.add('d-none');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onRemoveButtonClicked(e) {
    e.preventDefault();

    const clone = e.currentTarget.closest('.cloned');
    if (clone) {
      clone.remove();
      this.props.counter -= 1;
    }
    if (this.props.counter < this.props.counterMax) {
      this.dom.cloneButton.classList.remove('d-none');
    }

    this.updateAccordionItems();
  }

  onCreateClone() {
    const clone = this.dom.copyElem.cloneNode(true);

    clone.classList.add('cloned');

    Array.prototype.slice.call(clone.querySelectorAll('.form-group'))
      .forEach((formGroup) => {
        this.updateFormFields(formGroup);
      });

    // Get the remove button
    const removeBtn = clone.querySelector('.btn-remove');

    if (removeBtn) {
      removeBtn.disabled = false;
      removeBtn.addEventListener('click', this.events.onRemoveButtonClicked);
    }

    Array.prototype.slice.call(document.querySelectorAll('.accordion-collapse'))
      .forEach((el) => {
        const collapse = Collapse.getOrCreateInstance(el);
        collapse.hide();
      });

    this.dom.container.append(clone);
    clone.querySelectorAll('.accordion-collapse')[0].classList.add('show');

    this.updateAccordionItems();
  }

  updateFormFields(formGroup) {
    const el = formGroup;
    const formFieldEl = el.querySelector('.form-field');

    // Remove possible errors
    formFieldEl.classList.remove('is-invalid');

    // Remove the invalidation feedback
    if (el.nextSibling != null && el.nextSibling.classList.contains('invalid-feedback') === true) {
      el.nextSibling.innerHTML = '';
    }

    if (el.parentElement != null && el.parentElement.classList.contains('is-invalid') === true) {
      el.parentElement.classList.remove('is-invalid');
    }

    document.getElementById('form-participants').classList.remove('was-validated');

    // Set the new index
    if (formFieldEl.name) {
      formFieldEl.name = formFieldEl.name.replace(/\[\d+](?!.*\[\d)/, `[${this.props.counter}]`);
    }
    if (formFieldEl.id) {
      formFieldEl.id = formFieldEl.id.replace(/-\d+-(?!.*-\d)/, `-${this.props.counter}-`);
    }

    const formfieldTagName = formFieldEl.tagName.toLowerCase();
    const formfieldType = formFieldEl.getAttribute('type');

    let i;

    if (formFieldEl.dataset.datepicker !== undefined) {
      // eslint-disable-next-line no-new
      new Datepicker({
        element: formFieldEl, lang: formFieldEl.dataset.datepicker, minDate: formFieldEl.dataset.datepickerMinDate, maxDate: formFieldEl.dataset.datepickerMaxDate,
      });
    }

    if (formfieldTagName === 'select') {
      for (i = 0; i < formFieldEl.length; i += 1) {
        formFieldEl.options[i].selected = false;
      }
      // Add selected attribute to the first option
      formFieldEl.getElementsByTagName('option')[0].selected = true;
    } else if (formfieldTagName === 'input' && formfieldType === 'checkbox') {
      formFieldEl.checked = false;
    } else if (formfieldTagName === 'input' && formfieldType === 'radio') {
      if (formFieldEl.checked === false) {
        formFieldEl.checked = true;
      }
    } else {
      formFieldEl.value = '';
    }

    // Get the label
    const label = formGroup.querySelector('label');

    if (label !== null) {
      // Set the updated id
      label.setAttribute('for', formFieldEl.id);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  updateAccordionItems() {
    Array.prototype.slice.call(document.querySelectorAll('.accordion-item'))
      .forEach((accordionItem, index) => {
        const item = accordionItem;
        const counter = item.querySelector('.counter');
        const accordionHeader = item.querySelector('.accordion-header');
        const accordionCollapse = item.querySelector('.accordion-collapse');
        const accordionCollapseAccreditation = item.querySelector('.accreditation-collapse');
        const accordionButton = item.querySelector('.accordion-button');
        const accordionRadioButtons = item.querySelectorAll("input[type='radio']");

        counter.textContent = index + 1;
        accordionHeader.id = `course-participants-heading-${index + 1}`;
        accordionButton.dataset.bsTarget = `#course-participants-collapse-${index + 1}`;
        accordionButton.setAttribute('aria-controls', `course-participants-collapse-${index + 1}`);
        accordionCollapse.id = `course-participants-collapse-${index + 1}`;
        accordionCollapse.setAttribute('aria-labelledby', `course-participants-heading-${index + 1}`);
        accordionCollapseAccreditation.id = `course-participantsAccreditation-collapse-${index + 1}`;
        accordionCollapseAccreditation.classList.remove('show');

        Array.prototype.slice.call(accordionRadioButtons)
          .forEach((el) => {
            // eslint-disable-next-line no-param-reassign
            el.dataset.bsTarget = `#course-participantsAccreditation-collapse-${index + 1}`;
          });
      });
  }
}

import { rootWithLanguage } from '@/js/utils/paths';
import axios from 'axios';
import Autocomplete from '@/js/components/search/autocomplete';

export default class SearchOverlay {
  constructor(modalContainer) {
    this.dom = {
      modalContainer,
      searchForm: document.getElementById('website-search-form'),
      searchFormInput: document.getElementById('website-search-form-input'),
    };

    this.data = {
      // https://www.fusejs.io/api/options.html#options
      fuseOptions: {
        isCaseSensitive: false,
        shouldSort: true,
        minMatchCharLength: 3,
        threshold: 0.3,
        includeMatches: true,
        keys: ['name', 'keywords'],
      },
      autocompleteOptions: {
        maxResults: 5,
        itemHeading: 'category',
        itemName: 'name',
        itemUrl: 'url',
      },
      questionsApiUrl: `${rootWithLanguage}suggestions`,
    };

    this.autocomplete = null;

    this.mount();
  }

  mount() {
    this.autofocusInput();
    this.dom.modalContainer.addEventListener('shown.bs.modal', () => {
      this.initAutoComplete();
      this.autofocusInput();
    });
  }

  async initAutoComplete() {
    if (this.dom.searchForm) {
      try {
        // fetch data
        const response = await axios.get(this.data.questionsApiUrl);
        // initialize autocomplete
        this.autocomplete = new Autocomplete({
          autocompleteContainer: this.dom.searchForm,
          data: response.data ?? [],
          autocompleteOptions: this.data.autocompleteOptions,
          fuseOptions: this.data.fuseOptions,
        });
      } catch (error) {
        throw Error(error);
      }
    }
  }

  autofocusInput() {
    this.dom.searchFormInput.focus();
  }
}

import ActionHandler from '@/js/components/forms/action_handler';
import { rootWithLanguage } from '@/js/utils/paths';

class Detail extends ActionHandler {
  constructor() {
    super();
    this.dom = {
      dropdownItems: document.querySelectorAll('.dropdown-item'),
      phasePanelButtons: document.getElementById('phase-selection').querySelectorAll('.accordion-button'),
      phaseSubmitButtons: document.querySelectorAll('.btn-course-submit'),
    };

    this.data = {
      phaseID: null,
      courseID: null,
    };

    this.events = {
      onDropdownItemClick: this.onDropdownItemClick.bind(this),
      onPhasePanelButtonClick: this.onPhasePanelButtonClick.bind(this),
      onPhaseSubmitButtonClick: this.onPhaseSubmitButtonClick.bind(this),
    };

    this.mount();
  }

  mount() {
    this.dom.dropdownItems.forEach((dropdownItem) => {
      dropdownItem.addEventListener('click', this.events.onDropdownItemClick);
    });
    this.dom.phasePanelButtons.forEach((phasePanelButton) => {
      phasePanelButton.addEventListener('click', this.events.onPhasePanelButtonClick);
    });
    this.dom.phaseSubmitButtons.forEach((phaseSubmitButton) => {
      phaseSubmitButton.addEventListener('click', this.events.onPhaseSubmitButtonClick);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  onDropdownItemClick(e) {
    this.updateDropdownLabel(e.currentTarget.dataset.collapseId, e.currentTarget.dataset.collapseTitle);
    this.data.phaseID = e.currentTarget.dataset.phaseId;
    this.data.courseID = e.currentTarget.dataset.courseId;
    const formData = new FormData();
    formData.append('PhaseID', this.data.phaseID);
    formData.append('CourseID', this.data.courseID);
    this.send({ url: `${rootWithLanguage}select-phase`, formData });
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  onPhasePanelButtonClick(e) {
    this.updateDropdownLabel(e.currentTarget.dataset.collapseId, e.currentTarget.dataset.collapseTitle);
    this.data.phaseID = e.currentTarget.dataset.phaseId;
    this.data.courseID = e.currentTarget.dataset.courseId;
    const formData = new FormData();
    formData.append('PhaseID', this.data.phaseID);
    formData.append('CourseID', this.data.courseID);
    this.send({ url: `${rootWithLanguage}select-phase`, formData });
  }

  // eslint-disable-next-line class-methods-use-this
  onPhaseSubmitButtonClick(e) {
    e.preventDefault();
    this.data.phaseID = e.currentTarget.dataset.phaseId;
    this.data.courseID = e.currentTarget.dataset.courseId;
    const formData = new FormData();
    formData.append('PhaseID', this.data.phaseID);
    formData.append('CourseID', this.data.courseID);
    this.send({ url: `${rootWithLanguage}course-register`, formData });
  }

  // eslint-disable-next-line class-methods-use-this
  updateDropdownLabel(ID, title) {
    document.getElementById(ID).querySelector('.dropdown-toggle > span').textContent = title;
  }
}

if (document.getElementById('page-course-detail')) {
  // eslint-disable-next-line no-new
  new Detail();
}
